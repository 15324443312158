import { steps } from "../utils/data";

const WantedPosterSteps = () => {
  return (
    <div className="py-[60px]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="lg:text-[40px] md:text-[40px] text-[30px]   font-bold text-center text-[#534026] ">
            Create Your One Piece Wanted Poster
          </h2>
          <p className="mt-4 text-xl text-[#e9dec0]">
            Become a legendary pirate in just 4 simple steps
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-6 bg-[#e9dec0] rounded-lg shadow-md hover:shadow-xl transition-all duration-300"
            >
              <div className="bg-[#534026] text-white rounded-full p-4 mb-4">
                <step.icon className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-[#534026] mb-3">
                {step.title}
              </h3>
              <p className="text-[#534026] opacity-[.6]">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default WantedPosterSteps;
