import React, { useState } from "react";
import { GoChevronDown } from "react-icons/go";
import { faqItems } from "../utils/data";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="w-full max-w-2xl mx-auto">
      <h2 className="lg:text-[40px] md:text-[40px] text-[30px]   font-bold text-center text-[#534026] mb-12">
        Frequently Asked Questions
      </h2>
      {faqItems.map((item, index) => (
        <div
          key={item.title}
          className="border-b border-[#c2b49a] last:border-b-0"
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full flex justify-between items-center 
              text-left p-4  focus:outline-none 
              transition-colors duration-200"
          >
            <h3 className="text-[#534026]">{item.title}</h3>
            <GoChevronDown
              className={`w-5 h-5 text-[#534026] transition-transform duration-200 
                ${activeIndex === index ? "rotate-180" : ""}`}
            />
          </button>

          {activeIndex === index && (
            <div
              className="p-4  text-[#ffff] 
                border-t border-[#c2b49a]
                transition-all duration-300 ease-in-out"
            >
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default Accordion;
