import { FaRegUserCircle } from "react-icons/fa";
import { FiDollarSign } from "react-icons/fi";
import { IoImageOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";

export const faqItems = [
  {
    title: "Is this One Piece wanted poster maker free?",
    content: (
      <p>
        Yes! You can create and download posters with our watermark completely
        free. For watermark-free posters, we offer a premium download option for
        $3.99.
      </p>
    ),
  },
  {
    title: "What's the difference between free and premium downloads?",
    content: (
      <div>
        <p className="mb-2">
          Free Version: Includes a small "
          <a href="onepiececanvas.com">onepiececanvas.com</a>" watermark
        </p>
        <p>
          Premium Version ($3.99): Clean poster without any watermark, perfect
          for printing and professional use
        </p>
      </div>
    ),
  },
  {
    title: "Why should I get the premium version?",
    content: (
      <div>
        <p className="mb-2">
          The premium version gives you a clean, professional-looking poster
          without any watermarks. It's perfect for:
        </p>
        <ul className="list-disc list-inside pl-2">
          <li>High-quality prints</li>
          <li>Professional displays</li>
          <li>Social media profiles</li>
          <li>Cosplay photography</li>
          <li>Streaming overlays</li>
        </ul>
      </div>
    ),
  },
  {
    title: "How do I purchase the watermark-free version?",
    content: (
      <p>
        Simply click the "Download without watermark" button and follow the
        secure payment process. After payment, you'll receive your clean poster
        instantly.
      </p>
    ),
  },
];
export const steps = [
  {
    icon: FaRegUserCircle,
    title: "Enter your name",
    description:
      "Create your unique pirate identity. Choose a name that will strike fear (or amusement) into the hearts of other pirates!",
  },
  {
    icon: FiDollarSign,
    title: "Set your bounty amount",
    description:
      "Determine your notoriety level. Will you be a small-time rookie or a legendary pirate with a massive bounty?",
  },
  {
    icon: IoImageOutline,
    title: "Upload your image",
    description:
      "Select the perfect photo that captures your pirate spirit. A dramatic pose or fierce expression works best!",
  },
  {
    icon: GoDownload,
    title: "Choose your download option",
    description:
      "Pick between a free version with a watermark or a premium watermark-free poster for just $3.99.",
  },
];
