import React from "react";

const Footer = () => {
  return (
    <div className="py-[60px] grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-7 justify-between">
      <div className="flex flex-col gap-5">
        <h3 className="text-[24px] font-bold text-[#534026]">
          One Piece Canvas
        </h3>
        <div>
          <p className="text-[16px]">Generate your own wanted poster!</p>
          <p className="text-[13px] mt-2">
            © 2024 One Piece Canvas. All rights reserved.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <h3 className="text-[24px] font-bold text-[#534026]">Links</h3>
        <div className="flex flex-col gap-2">
          <a href="/" className="text-[16px]">
            Home
          </a>
          <a href="#" className="text-[16px]">
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <h3 className="text-[24px] font-bold text-[#534026]">Get in Touch</h3>
        <div className="flex flex-col gap-2">
          <a href="mailto:onepiececanvas047@gmail.com" className="text-[16px]">
            E-mail
          </a>
          <a
            href="http://tiktok.com/@onepiece_wanted_posters"
            className="text-[16px]"
          >
            TikTok
          </a>
          <a
            href="https://www.instagram.com/onepiece_wanted_posters"
            className="text-[16px]"
          >
            Instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
