import React, { useEffect, useRef, useState } from "react";
import FormWrap from "./components/FormWrap";
import WantedPoster from "./components/WantedPoster";
import templateImage from "./assets/poster-template.png";
import Twitter from "./components/Buttons/Twitter";
import WhatsApp from "./components/Buttons/WhatsApp";
import Messenger from "./components/Buttons/Messenger";
import Telegram from "./components/Buttons/Telegram";
import Pirates from "./components/Pirates";
import Footer from "./components/Footer";
import { IoMdClose } from "react-icons/io";
import { PaystackButton } from "react-paystack";
import Banner from "./components/Banner";
import Accordion from "./components/Accordion";
import WantedPosterSteps from "./components/WantedPosterSteps";
import { fetchIpAddress } from "./services";

const App = () => {
  const [isNigerian, setIsNigerian] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const publicKey = "pk_live_5f35270ef0c3e85944a953d5a7eaecbd1f93440e";
  const canvasRef = useRef(null);
  const [template, setTemplate] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [name, setName] = useState("Monkey D Luffy");
  const [bounty, setBounty] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const amountInNaira = 5499 * 100;
  const amountInUSD = 3.99 * 100;

  const componentProps = {
    email: formData.email,
    amount: currency === "NGN" ? amountInNaira : amountInUSD,
    metadata: {
      name,
    },
    publicKey,
    currency,
    text: "Pay Now",
    onSuccess: () => {
      setIsModalOpen(false);
      setFormData({
        phoneNumber: "",
        email: "",
      });
      downloadCanvas(false);
    },
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => setUploadedImage(img);
    img.onerror = () => console.error("Failed to load uploaded image");
  };

  const drawCanvas = (ctx, includeWatermark = true) => {
    const canvas = canvasRef.current;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    if (template) {
      const imgWidth = template.width;
      const imgHeight = template.height;

      const imgAspectRatio = imgWidth / imgHeight;
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let drawWidth, drawHeight, offsetX, offsetY;

      if (imgAspectRatio > canvasAspectRatio) {
        drawWidth = imgHeight * canvasAspectRatio;
        drawHeight = imgHeight;
        offsetX = (imgWidth - drawWidth) / 2;
        offsetY = 0;
      } else {
        drawWidth = imgWidth;
        drawHeight = imgWidth / canvasAspectRatio;
        offsetX = 0;
        offsetY = (imgHeight - drawHeight) / 2;
      }

      ctx.drawImage(
        template,
        offsetX,
        offsetY,
        drawWidth,
        drawHeight,
        0,
        0,
        canvasWidth,
        canvasHeight
      );

      if (uploadedImage) {
        const frameX = 162;
        const frameY = 530;
        const frameWidth = 1430;
        const frameHeight = 1038;

        const uploadedAspectRatio = uploadedImage.width / uploadedImage.height;
        const frameAspectRatio = frameWidth / frameHeight;

        let sourceX, sourceY, sourceWidth, sourceHeight;

        if (uploadedAspectRatio > frameAspectRatio) {
          sourceHeight = uploadedImage.height;
          sourceWidth = sourceHeight * frameAspectRatio;
          sourceX = (uploadedImage.width - sourceWidth) / 2;
          sourceY = 0;
        } else {
          sourceWidth = uploadedImage.width;
          sourceHeight = sourceWidth / frameAspectRatio;
          sourceX = 0;
          sourceY = (uploadedImage.height - sourceHeight) / 2;
        }

        ctx.save();
        ctx.beginPath();
        ctx.rect(frameX, frameY, frameWidth, frameHeight);
        ctx.clip();

        ctx.fillStyle = "#534026";
        ctx.fillRect(frameX, frameY, frameWidth, frameHeight);

        ctx.globalCompositeOperation = "luminosity";
        ctx.drawImage(
          uploadedImage,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          frameX,
          frameY,
          frameWidth,
          frameHeight
        );

        ctx.globalCompositeOperation = "source-over";
        ctx.restore();
      }

      if (name) {
        const modifiedName = name.toUpperCase().replace(/ /g, "•");

        ctx.fillStyle = "#534026";
        ctx.font = "bold 380px 'Bauer'";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const maxWidth = 1400;
        const textX = 890;
        const textY = 1950;

        const textWidth = ctx.measureText(modifiedName).width;
        const scaleFactor = textWidth > maxWidth ? maxWidth / textWidth : 1;

        ctx.save();
        ctx.translate(textX, textY);
        ctx.scale(scaleFactor, 1);
        ctx.translate(-textX, -textY);

        ctx.fillText(modifiedName, textX, textY);
        ctx.restore();
      }

      if (bounty) {
        const modifiedBounty = `${Number(bounty).toLocaleString()}`;

        ctx.fillStyle = "#534026";
        ctx.font = "bold 210px 'Bauer'";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const textX = 890;
        const textY = 2180;

        const maxBountyWidth = 1200;
        const bountyTextWidth = ctx.measureText(modifiedBounty).width;
        const bountyScaleFactor =
          bountyTextWidth > maxBountyWidth
            ? maxBountyWidth / bountyTextWidth
            : 1;

        ctx.save();
        ctx.translate(textX, textY);
        ctx.scale(bountyScaleFactor, 1);
        ctx.translate(-textX, -textY);

        ctx.fillText(modifiedBounty, textX, textY);
        ctx.restore();
      }

      if (includeWatermark) {
        const watermarkText = "ONEPIECECANVAS.COM";
        ctx.save();
        ctx.font = "bold 118px Arial";
        ctx.fillStyle = "rgba(255, 255, 255, 0.6)";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const watermarkX = 880;
        const watermarkY = 1470;

        ctx.fillText(watermarkText, watermarkX, watermarkY);
        ctx.restore();
      }
    }
  };

  const downloadCanvas = (withWatermark) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    drawCanvas(ctx, withWatermark);

    const link = document.createElement("a");
    link.download = withWatermark
      ? "poster_with_watermark.png"
      : "poster_without_watermark.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  useEffect(() => {
    const img = new Image();
    img.src = templateImage;
    img.onload = () => setTemplate(img);
    img.onerror = () => console.error("Failed to load template image");
    const fetchUserLocation = async () => {
      try {
        const ipData = await fetchIpAddress();
        if (ipData.country === "Nigeria") {
          setIsNigerian(true);
          setCurrency("NGN");
        } else {
          console.log(false);
          setIsNigerian(false);
          setCurrency("USD");
        }
      } catch (error) {
        console.error("Error fetching user location:", error);
      }
    };

    fetchUserLocation();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    drawCanvas(ctx, true);
    // eslint-disable-next-line
  }, [template, uploadedImage, name, bounty]);

  const url = "https://onepiececanvas.com";
  const shareText =
    "Make your own One Piece wanted poster at https://OnePieceCanvas.com. It's super easy 🏴‍☠👒";

  return (
    <main>
      <Banner />
      <div className="max-w-screen-xl mx-auto p-[25px] pt-[90px] container">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-5 ">
          <div className="flex justify-center items-center">
            <FormWrap
              handleImageUpload={handleImageUpload}
              name={name}
              bounty={bounty}
              setName={setName}
              setBounty={setBounty}
              downloadCanvas={downloadCanvas}
              setFormData={setFormData}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
          <div className="flex justify-center items-center">
            <WantedPoster canvasRef={canvasRef} />
          </div>
        </div>
        <div className="py-[60px] ">
          <div className="flex justify-start flex-col lg:flex-row md:flex-row lg:items-center md:items-center items-start gap-6 p-[15px] rounded-2xl bg-[#c2b49a]">
            <div className="flex items-center gap-2">
              <div className="bg-share w-[100px] h-[100px] hidden lg:block md:block"></div>
              <div className="flex flex-col gap-1 justify-start">
                <p className="text-[15px] font-semibold normal-case text-[#534026]">
                  Share One Piece
                </p>
                <p className="text-[12px] font-normal normal-case text-[#534026]">
                  to your friends
                </p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-3 flex-wrap">
              <Twitter shareText={shareText} />
              <WhatsApp shareText={shareText} />
              <Messenger url={url} shareText={shareText} />
              <Telegram shareText={shareText} />
            </div>
          </div>
        </div>
        <WantedPosterSteps />
        <div className="py-[60px]">
          <Accordion />
        </div>
        <Pirates />
        <Footer />

        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={`Checkout — ${isNigerian ? 'NGN 5,499' : '$ 3.99'}`}
          setFormData={setFormData}
          formData={formData}
          componentProps={componentProps}
          isNigerian={isNigerian}
          currency={currency}
          handleCurrencyChange={handleCurrencyChange}
        />
      </div>
    </main>
  );
};

export default App;

const Modal = ({
  isOpen,
  onClose,
  title,
  setFormData,
  formData,
  componentProps,
  isNigerian,
  currency,
  handleCurrencyChange,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div className="relative w-full max-w-lg mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
            <h3 className="text-2xl font-semibold text-black">{title}</h3>
            <button
              className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none focus:outline-none"
              onClick={onClose}
            >
              <IoMdClose />
            </button>
          </div>

          <div className="relative flex-auto p-6">
            <div className="">
              <form className="space-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={
                      "w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker"
                    }
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                {isNigerian && (
                  <div>
                    <label
                      htmlFor="currency"
                      className="block mb-2 text-sm font-bold text-gray-700"
                    >
                      Select Payment Currency
                    </label>
                    <select
                      id="currency"
                      value={currency}
                      onChange={handleCurrencyChange}
                      className={
                        "w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker"
                      }
                    >
                      <option value="NGN">NGN (₦)</option>
                      <option value="USD">USD ($)</option>
                    </select>
                  </div>
                )}
              </form>
              <div className="flex items-center justify-end mt-6 border-t border-solid rounded-b border-blueGray-200 pt-4">
                <button
                  className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                  type="button"
                  onClick={onClose}
                >
                  Close
                </button>
                <PaystackButton
                  {...componentProps}
                  disabled={!formData.email}
                  className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-green-500 rounded shadow hover:shadow-lg outline-none active:bg-green-600 focus:outline-none"
                  text="Checkout"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
