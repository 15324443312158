export const fetchIpAddress = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    if (!response.ok) {
      throw new Error("Failed to fetch IP data");
    }
    const ipData = await response.json();
    return {
      ip: ipData.ip,
      country: ipData.country_name,
      city: ipData.city,
      region: ipData.region,
    };
  } catch (error) {
    console.error("Error fetching IP data:", error);
    return null;
  }
};
