import React from "react";

const Banner = () => {
  return (
    <div className="h-[60px] p-6 bg-[#534026] w-full flex justify-center items-center fixed z-[9999]">
      <h4 className="info text-[14px] text-white text-center">
        ⭐ NEW: Get Your Wanted Poster Framed! Free Worldwide Shipping - $44.99
        →{" "}
        <a
          href="https://paystack.shop/one-piece-wanted-posters"
          target="_blank"
          className="underline"
        >
          Click here
        </a>
      </h4>
    </div>
  );
};

export default Banner;
